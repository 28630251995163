import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from 'styled-components';
import BgImage from '../images/banner-images/about-banner.jpg';
import Banner from "../components/banner";

const AboutPage = () => (
  <Layout>
    <Seo title="About" description="Hi I'm Tom. A web developer with experience in developing a wide variety of websites within a number of industries." />

    <Banner title="About" image={BgImage} />

    <Main>
      <p>Hi, I'm Tom. I’m an experienced web developer with years of experience developing a wide variety of brochure and e-commerce websites within a number of industries, for a range of organisations.</p>

      <p>My mission is simply to develop a strong online presence for those who need it and get their message out there. I especially feel passionate about helping not-for-profit organisations, charities and small businesses. You can find out more about my services <a href="/services">here</a> and some examples of people I have helped <a href="/portfolio">here</a>.</p>

      <p>I'm not only passionate about web development, I also love of a good cuppa coffee! When I’m not online I love to take my camera out and explore new places in the company of good friends. Why not give me a follow on <a href="https://www.instagram.com/t0m_snaps/" target="_blank" rel="noreferrer">instagram</a>?</p>

      <p>If you want to find out more about me or how I can help you, please do get in <a href="/contact">contact</a>.</p>

    </Main>


  </Layout>
)

export default AboutPage

const Main = styled.div`
    max-width: 1000px;
    margin: 60px auto;
    color: #fff;

    a {
      color: red;
      text-decoration: none;
    }

    @media(max-width: 1015px) {
      margin: 40px 20px;
    }
`